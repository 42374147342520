































import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { DealerStoreActions, DealerStoreGetters } from '@/store/dealer.store';
import Dealer from '@/models/Dealer.model';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const DealerStore = namespace('dealer');

@Component({
  components: {
    DealerListComponent: () => import(
      /* webpackChunkName: "DealerListComponent" */
      '@/components/DealerList.component.vue'
    ),
    EditDealerComponent: () => import(
      /* webpackChunkName: "EditDealerComponent" */
      '@/components/EditDealer.component.vue'
    )
  }
})
export default class DealerView extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @DealerStore.Action(DealerStoreActions.GET_ALL)
  private getAllDealersAction!: () => Promise<Dealer[]>;

  @DealerStore.Getter(DealerStoreGetters.DEALERS)
  private dealers!: Dealer[];

  private isLoading: boolean = false;

  private showCreationDialog: boolean = false;
  private showEditDialog: boolean = false;

  private selectedDealer?: Dealer;

  private search: string = '';

  async created() {
    try {
      this.isLoading = true;
      await this.getAllDealersAction();
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public startEditing(dealer: Dealer) {
    this.showEditDialog = true;
    this.selectedDealer = dealer;
  }

  private async closeDialog(reload: boolean) {
    this.showCreationDialog = false;
    this.showEditDialog = false;
    if (reload) {
      try {
        await this.getAllDealersAction();
      } catch (e) {
        this.handleAxiosError(e);
      }
    }
  }
}

